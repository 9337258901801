body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::selection {
  background: rgba(130,150,1420,200); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: rgba(); /* WebKit/Blink Browsers */
}

::-webkit-scrollbar {
  display: none;
}

a {
  color: white;
  text-decoration: 'none';
}

@font-face {
  font-family: conv;
  src: url(./fonts/conv.ttf) format("truetype");
}
@font-face {
  font-family: px;
  src: url(./fonts/px.ttf) format("truetype");
}
@font-face {
  font-family: redcoat;
  src: url(./fonts/redcoat.ttf) format("truetype");
}
@font-face {
  font-family: vip;
  src: url(./fonts/vip.ttf) format("truetype");
}
@font-face {
  font-family: frutiger;
  src: url(./fonts/Frutiger.ttf) format("truetype");
}

@font-face {
  font-family: powerline;
  src: url(./fonts/ProFont\ For\ Powerline.ttf) format("truetype");
}
@font-face {
  font-family: ibmiso8;
  src: url(./fonts/Px437_IBM_ISO8.ttf) format("truetype");
}
@font-face {
  font-family: compaq;
  src: url(./fonts/Px437_CompaqThin_8x14.ttf) format("truetype");
}

@font-face {
  font-family: lunchtype;
  src: url(./fonts/Lunchtype24-Regular-Expanded.ttf) format("truetype");
}



.fadeIn {
  animation: fadeIn ease 12s;
  -webkit-animation: fadeIn ease 12s;
  -moz-animation: fadeIn ease 12s;
  -o-animation: fadeIn ease 12s;
  -ms-animation: fadeIn ease 12s;
}

@keyframes fadeIn {
  0% {
    filter: blur(120px) hue-rotate(-5deg);
  }
  100% {
    filter: blur(0px) hue-rotate(0deg);
  }
}

@-moz-keyframes fadeIn {
  0% {
    filter: blur(120px) hue-rotate(-5deg);
  }
  100% {
    filter: blur(0px) hue-rotate(0deg);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    filter: blur(120px) hue-rotate(-5deg);
  }
  100% {
    filter: blur(0px) hue-rotate(0deg);
  }
}

@-o-keyframes fadeIn {
  0% {
    filter: blur(120px) hue-rotate(-5deg);
  }
  100% {
    filter: blur(0px) hue-rotate(0deg);
  }
}

@-ms-keyframes fadeIn {
  0% {
    filter: blur(120px) hue-rotate(-5deg);
  }
  100% {
    filter: blur(0px) hue-rotate(0deg);
  }
}
