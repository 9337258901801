$grey_1 : rgb(170,170,170);
$grey_1_darker : rgb(145, 145, 145);
$theme_powder: rgba(153, 188, 250, 0.39);
$green:rgb(158, 221, 166);
.container {
    width: 100vw;
    height: 30px;
    background-color: $grey_1;
    position: fixed;
    border-top: 2px solid $grey_1_darker ;
    bottom: 0;
}

.bar {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.tab_start {
    box-sizing: border-box;
    margin: 1px;
    width: 60px;
    background-color:  rgb(219, 219, 219);
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid rgb(80, 80, 80);
    border-bottom: 2px solid rgb(53, 53, 53);
    display: flex;
    flex-direction: row;
    transform: skewX(-10deg);
    border-radius: 10px;
    justify-content: center;
    margin-left: 4px;
    align-items: center;
    font-family: 'vip';
    color: rgb(80, 80, 80);
}


.tab {
    font-family: 'helvetica';
    box-sizing: border-box;
    margin: 1px;
    width: 100px;
    background-color:  rgb(219, 219, 219);
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid rgb(80, 80, 80);
    border-bottom: 2px solid rgb(53, 53, 53);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transform: skewX(-10deg);
    margin-left: 2px;
    width: 130px;

    
}

.focused {
    // background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(159, 194, 171, 0.411));
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid rgb(80, 80, 80);
    border-bottom: 2px solid rgb(87, 86, 86);
    // transform: skewX(0deg);
    
    
}

.title {
    font-family: 'frutiger';
    font-size: 0.8em;
    color:rgb(80, 80, 80); 
    font-weight: bold;
 
    // margin-bottom: -10;
}



